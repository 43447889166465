const theDate = new Date();
const year = theDate.getFullYear();
const first = "Christina";
const last = "Gohman";

export const config = {
    author: {
        name: {
            first: first,
            last: last,
            preferred: "Christina",
        },
        email: "Me@ChristinaGohman.com",
        phone: "(626) 765-5924",
        tel: "16267655924",
    },
    header: {
        fullname: `${first} ${last}`,
        title: `Marketing Graphic Designer`,
    },
    footer: {
        tagline: `Designed and Developed by Christina &amp; Mitchell ${last}`,
        copyright: `${year}. All rights reserved.`,
    },
};
